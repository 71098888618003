<template>
  <div class="login">
    <div class="login-box">
      <div class="white-l-box text-center">
        <img src="/assets/img/logo-big.png" alt="" />
        <h2 class="bold-19 text-gray mt-2 mb-4">Prodyflow</h2>
        <h2 class="bold-24 text-black mt-3">{{ $t("forgottenPassword.forgottenPassword") }}</h2>
        <form v-on:submit.prevent="restore()">
          <div class="row text-start custom-input whitebg-input mt-4">
            <div class="col-12">
              <div class="mb-4">
                <label class="form-label bold-12 text-gray">{{ $t("finance.email") }}</label>
                <input type="email" class="form-control remove-padding" :placeholder="$t(`finance.emailAddress`)" v-model="email">
              </div>
              <div ref="captcha"></div>
              <div class="error my-2 text-danger fw-bold" v-if="error">{{ error }}</div>
              <div class="error my-2 text-success fw-bold" v-else-if="success">{{ success }}</div>
              <button type="submit" ref="submit" class="btn btn-type-2 w-100 text-center mt-2 pt-3 pb-3 mb-4">
                <span class="bold-14">{{ $t("forgottenPassword.restoreAccount") }}</span>
              </button>
            </div>
          </div>
        </form>
        <h5 class="regular-14 text-gray">
          {{ $t("forgottenPassword.knowYourPassword") }}
          <router-link to="/login" class="semibold-14 text-gradient pointer">{{ $t("forgottenPassword.logIn") }}</router-link>
        </h5>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ForgottenPassword",
  data() {
    return {
      email: "",
      error: null,
      success: null,
      needCaptcha: false,
      captchaID: null,
    };
  },
  mounted() {
    this.captcha();
  },
  methods: {
    captcha() {
      if (this.needCaptcha) {
        // eslint-disable-next-line no-undef
        hcaptcha.reset(this.captchaID);
      } else {
        this.needCaptcha = true;
        // eslint-disable-next-line no-undef
        this.captchaID = hcaptcha.render(this.$refs.captcha, {
          sitekey: "40502656-4482-4a91-9999-797738a6dd8d",
        });
      }
    },
    restore() {
      this.$refs.submit.disabled = true;
      let data = new FormData();
      data.append("email", this.email);
      // eslint-disable-next-line no-undef
      data.append("h-captcha-response", hcaptcha.getResponse(this.captchaID));

      fetch(process.env.VUE_APP_API+"/auth/forgotten-password", {
        method: "POST",
        body: data,
        headers: { Accept: "application/json" },
      })
        .then((res) => {
          if (!res.ok) {
            const error = new Error(res.statusText);
            error.json = res.json();
            throw error;
          }
          return res.json();
        })
        .then((data) => {
          this.success = data.message;
        })
        .catch((err) => {
          this.$refs.submit.disabled = false;
          err.json.then((data) => {
            if (data.needCaptcha || this.needCaptcha) {
              this.captcha();
            }
            this.error = data.message;
          });
        });

      return false;
    },
  },
};
</script>
